<template>
  <circle cx="35" cy="35" r="35" fill="#F1F4F7" />
  <rect
    x="9"
    y="29.9651"
    width="3.1619"
    height="3.1619"
    rx="1"
    transform="rotate(-38.4228 9 29.9651)"
    stroke="#DAE5F5"
    stroke-width="0.36"
    stroke-linecap="round"
  />
  <rect
    x="43.9875"
    y="53"
    width="5.97503"
    height="5.97503"
    rx="1"
    transform="rotate(30 43.9875 53)"
    stroke="#DDE3F0"
    stroke-width="0.5"
    stroke-linecap="round"
  />
  <rect
    x="35"
    y="10.4875"
    width="4.73568"
    height="4.73568"
    rx="1"
    transform="rotate(-31.6862 35 10.4875)"
    stroke="#DDE3F0"
    stroke-width="0.36"
    stroke-linecap="round"
  />
  <path
    d="M25 26C25 25.4477 25.4477 25 26 25H48C48.5523 25 49 25.4477 49 26V42.5858C49 42.851 48.8946 43.1054 48.7071 43.2929L43.2929 48.7071C43.1054 48.8946 42.851 49 42.5858 49H26C25.4477 49 25 48.5523 25 48V26Z"
    fill="#A3B1D1"
  />
  <path
    d="M23 24C23 23.4477 23.4477 23 24 23H46C46.5523 23 47 23.4477 47 24V40.5858C47 40.851 46.8946 41.1054 46.7071 41.2929L41.2929 46.7071C41.1054 46.8946 40.851 47 40.5858 47H24C23.4477 47 23 46.5523 23 46V24Z"
    fill="white"
  />
  <mask
    id="mask0"
    style="mask-type: alpha"
    maskUnits="userSpaceOnUse"
    x="23"
    y="23"
    width="24"
    height="24"
  >
    <path
      d="M23 24C23 23.4477 23.4477 23 24 23H46C46.5523 23 47 23.4477 47 24V40.5858C47 40.851 46.8946 41.1054 46.7071 41.2929L41.2929 46.7071C41.1054 46.8946 40.851 47 40.5858 47H24C23.4477 47 23 46.5523 23 46V24Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask0)">
    <path opacity="0.45" d="M41 41V45L45 41H41Z" fill="#DDE3F0" />
  </g>
  <path
    d="M40.5 45.5L40.5 40.5H45.5"
    stroke="#DDE3F0"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M32.8232 20.1768L32.0732 20.9268C32.0263 20.9737 32 21.0372 32 21.1036V25C32 25.5523 32.4477 26 33 26H37C37.5523 26 38 25.5523 38 25V21.1036C38 21.0372 37.9737 20.9737 37.9268 20.9268L37.1768 20.1768C37.0791 20.0791 36.9209 20.0791 36.8232 20.1768L36.1768 20.8232C36.0791 20.9209 35.9209 20.9209 35.8232 20.8232L35.1768 20.1768C35.0791 20.0791 34.9209 20.0791 34.8232 20.1768L34.1768 20.8232C34.0791 20.9209 33.9209 20.9209 33.8232 20.8232L33.1768 20.1768C33.0791 20.0791 32.9209 20.0791 32.8232 20.1768Z"
    fill="#DDE3F0"
  />
  <rect x="27" y="31" width="16" height="1" rx="0.5" fill="#8497C2" />
  <rect x="27" y="35" width="9" height="1" rx="0.5" fill="#8497C2" />
</template>
